.schedule {
    display: flex;
}

.schedule-tirsdag {
    font-size: 22px;
}

.schedule-onsdag {
    font-size: 22px;
    margin-top: 1.75rem;
    margin-left: auto;
}
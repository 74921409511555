.card-and-chevron {
    width: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20rem;
}

.kort-header {
    margin-top: 2rem;
    font-size: 3rem;
    text-align: center;
}

.kort-date {
    font-size: 1.5rem;
    margin-top: 1rem;
}

.kort {
    margin-top: 2rem;
    width: 100%;
}

.chevron {
    margin-top: 2rem;
    transform: rotate(90deg);
    width: 5rem;
}

.chevron-dip {
    animation: wiggle;
    animation-iteration-count: infinite;
    animation-delay: 3s;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

@keyframes wiggle {
    0% {
        transform: translateY(0);
    }
    5% {
        transform: translateY(10px);
    }
    10% {
        transform: translateY(0)
    }
    15% {
        transform: translateY(10px);
    }
    20% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}
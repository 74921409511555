html {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #f5f5f0;
    padding: 0 10%;
    box-sizing: border-box;
}

#root {
    display: flex;
    justify-content: center;
}

body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.text-bold {
    font-weight: 800;
}

.margin-top-1rem {
    margin-top: 1rem;
}

.margin-bottom-1rem {
    margin-bottom: 1rem;
}

.back-button {
    cursor: pointer;
    margin-top: 1rem;
}

.back-button:hover {
    text-decoration: underline;
}

.back-button:visited {
    color: black;
}
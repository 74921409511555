.container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 200;

    max-width: 20rem;
}

.kleivstua-container {
    width: 100%;
}

.check-out-the-program-container {
    width: 100%;
    margin-top: 15rem;
}

.schedule-container {
    width: 100%;
    height: 10rem;
    margin-top: 15rem;
}

.toastmaster-container {
    width: 100%;
    margin-top: 20rem;
}

.more-information-container {
    width: 100%;
    margin-top: 20rem;
    min-height: 40rem;
}

.rsvp-container {
    width: 100%;
    margin-bottom: 8rem;
}

.oss-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 10rem;
}

.oss-to {
    width: 80%;
}

.timeline-wrapper {
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.programmet-header {
    font-size: 22px;
}

.timeline-day {
    font-size: 18px;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.event-wrapper {
    margin-bottom: 1rem;
}

.event-info-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.25rem;
}

.event-time {
    width: 4rem;
}

.event-header {
    font-weight: 450;

}

.event-description {
    padding-left: 4rem;
    font-size: 12px;
}

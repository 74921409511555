.toastmaster {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.toastmaster-heading-1 {
    font-size: 22px;
}

.toastmaster-heading-2 {
    font-size: 20px;
}

.aurora {
    width: 80%;
    margin-top: 1rem;
}

.contact-info {
    font-size: 17px;
    margin-top: 1rem;
    line-height: 1.75rem;
}
.heading-1 {
    width: 100%;
    font-size: 22px;
}

.heading-2 {
    width: 100%;
    
    text-align: end;
    font-size: 28px;
    margin-top: 0.75rem;
}

.heading-2-strong {
    font-weight: 800;
    margin-left: 5px;
}

.kleivstua-bilder {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
}

.kleivstua-bilder-fade {
    display: flex;
    width: 100%;
}

.kleivstua-bilde {
    width: 85%;
    margin-bottom: 2rem;
}

.kleivstua-infotext {
    font-size: 15px;
}

.b1 {
    margin-right: auto;
}

.b2 {
    margin-left: auto;
}

.b3 {
    margin-right: auto;
}
.kommer-du {
    font-size: 22px;
}

.rsvp-form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.rsvp-input {
    margin-bottom: 1rem;
    height: 45px;
    border: none;
    padding-left: 5%;
    
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
}

.rsvp-input::placeholder {
    color: #5d5d5d;
}

.input-error {
    border: 1px solid salmon;
    background-color: rgba(255, 0, 0, 0.02);
}

.rsvp-margin-top {
    margin-top: 2rem;
}

.rsvp-checkbox {
    margin-top: 1.5rem;
    color: #5d5d5d;
}

.rsvp-button {
    height: 2.5rem;
    background-color: transparent;
    border: 1px solid #5d5d5d;
    width: fit-content;
    padding: 0% 12%;
    color: #5d5d5d;
    font-size: 16px;

    cursor: pointer;
}
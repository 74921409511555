.more-information-heading {
    font-size: 22px;
}

.more-information-content {
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: row;
}

.more-information-list {
    margin-top: 1.5rem; 
    width: 100%;
    transition: margin-left 0.5s ease;
    flex-basis: 100%;
}

.more-information-list-invisible {
    margin-left: -100%;
}

.more-information-item {
    margin-top: 1.5rem;
    text-decoration: underline;
    cursor: pointer;
}

.more-information-item:hover {
    font-weight: bold;   
}

.more-information-selected {
    width: 100%;
    margin-top: 2.5rem;
    margin-right: -100%;  
    padding-bottom: 5rem; 
}


.more-information-selected-textblock {
    margin-top: 1rem;
    font-size: 14px;
}